<template>
  <v-container v-if="!loading" fluid class="pa-0">
    <v-list class="py-0">
      <v-list-group
        :value="false"
        prepend-icon="mdi-account-supervisor"
        >
        <template v-slot:activator>
          <v-list-item-title>MASFARRE</v-list-item-title>
        </template>
        <template>
          <v-list-item
            @click="acceptUniforme"
            class="ms-10 sub-item"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-tie</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">UNIFORME</v-list-item-title>
            <v-list-item-icon>
              <v-icon :color="uniforme ? 'success' : 'error'">{{ uniforme ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            @click="acceptCc"
            class="ms-10 sub-item"
          >
            <v-list-item-icon>
              <v-icon>mdi-gavel</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">CÓDIGO CONDUCTA</v-list-item-title>
            <v-list-item-icon>
              <v-icon :color="cc ? 'success' : 'error'">{{ cc ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            @click="acceptPrl"
            class="ms-10 sub-item"
          >
            <v-list-item-icon>
              <v-icon>mdi-hard-hat</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">PREVENCIÓN RIESGOS LABORALES</v-list-item-title>
            <v-list-item-icon>
              <v-icon :color="prl ? 'success' : 'error'">{{ prl ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <template v-if="tree.direccion">
          <v-list-item
            class="ms-10 sub-item"
            v-for="(f,i) in tree.direccion"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon v-text="icon(f.name)"></v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" v-text="f.name"></v-list-item-title>
            <v-list-item-icon>
              <v-icon color="primary">mdi-download</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item-title>
            SIN DATOS
          </v-list-item-title>
        </template>
      </v-list-group>
      <v-divider></v-divider>
      <v-list-group
        :value="false"
        prepend-icon="mdi-account-tie"
      >
        <template v-slot:activator>
          <v-list-item-title>PERSONAL</v-list-item-title>
          <v-btn class="ms-auto" fab icon @click.stop="createFolder"><v-icon>mdi-folder-plus</v-icon></v-btn>
        </template>

        <v-list-group
          :value="false"
          no-action
          sub-group
          v-for="(d,i) in tree.empleado"
          :key="i"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" v-text="d.name"></v-list-item-title>
            </v-list-item-content>
            <v-icon>mdi-paperclip-plus</v-icon>
          </template>

          <v-list-item
            v-for="(f, i) in d.files"
            :key="i"
            link
          >
            <v-list-item-icon>
              <v-icon v-text="icon(f.name)"></v-icon>
            </v-list-item-icon>

            <v-list-item-title style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" v-text="f.name"></v-list-item-title>

            <v-list-item-icon @click.stop="download(d.name, f.name)">
              <v-icon v-text="'mdi-download'" color="primary"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list-group>
    </v-list>
    <ConfirmDialog ref="confirm" />
    <CreateFolder ref="createFolder" />
    <Uniforme ref="uniforme" />
    <Prl ref="prl" />
  </v-container>
  <v-container v-else>
    <div style="width: 100%;height: 90vh;" class="d-flex align-center justify-center">
      <v-progress-circular
        size="250"
        indeterminate
        color="primary"
      >
        <h2>Cargando</h2>
      </v-progress-circular>
    </div>
  </v-container>
</template>

<script>

export default {
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    CreateFolder: () => import("../components/CreateFolder.vue"),
    Uniforme: () => import("../components/Uniforme.vue"),
    Prl: () => import("../components/Prl.vue"),
  },
  data() {
    return {
      loading: false,
      tree: {},
      uniforme: false,
      cc: false,
      prl: false,
    };
  },
  async mounted() {
    this.loading = true
    await Promise.all([
      this.getDocumentos(),
      this.getObligatorios(),
    ])
    this.loading = false
  },
  computed:{
  },
  methods: {
    async acceptUniforme(){
      const uni = await this.$refs.uniforme.open()
      if (uni){
        try {
          const { data } = await axios({
            method: "POST",
            url: `/documentos_equipo/uniforme`,
          });
          this.uniforme = true
          this.$root.$emit("snack", "Documento aceptado.")
        } catch (error) {
          this.$root.$emit("snack", "Error de red.")
        }
      }
    },
    async acceptCc(){
      const uni = await this.$refs.cc.open()
      if (uni){
        try {
          const { data } = await axios({
            method: "POST",
            url: `/documentos_equipo/cc`,
          });
          this.cc = true
          this.$root.$emit("snack", "Documento aceptado.")
        } catch (error) {
          this.$root.$emit("snack", "Error de red.")
        }
      }
    },
    async acceptPrl(){
      const uni = await this.$refs.prl.open()
      if (uni){
        try {
          const { data } = await axios({
            method: "POST",
            url: `/documentos_equipo/prl`,
          });
          this.prl = true
          this.$root.$emit("snack", "Documento aceptado.")
        } catch (error) {
          this.$root.$emit("snack", "Error de red.")
        }
      }
    },
    async createFolder(){
      const newFolder = (await this.$refs.createFolder.open("Nueva carpeta", "Introduce el nombre de la carpeta:", { color: "primary" }))
      if (!newFolder) return
      try {
        const { data } = await axios({
          method: "POST",
          url: `/documentos_equipo/newFolder`,
          data: newFolder
        });
        this.$set(this.tree.empleado, this.tree.empleado.length, {name:newFolder, files: []})
        this.tree.empleado.sort((a,b) => {
          let keyA = a.name.toLowerCase();
          let keyB = b.name.toLowerCase();

          if (keyA < keyB) {
              return -1;
          }
          if (keyA > keyB) {
              return 1;
          }
          return 0;
        })
        this.$root.$emit("snack", "Carpeta creada correctamente.")
      } catch (error) {
        if (error.response) {
          this.$root.$emit("snack", error.response.data.detail);
        } else {
          this.$root.$emit("snack", "Error de red.");
        }
      }
    },
    icon(name){
      const imageExtensions = ["jpeg", "jpg", "png", "gif", "bmp", "tiff", "tif", "webp", "heic", "svg", "ico", "cr2", "nef", "arw", "rw2", "orf", "dng", "psd"];
      const excelExtensions = ["xls","xlam","csv","xlsb","xlsx","xltx"];
      const audioExtensions = ["mp3", "wav", "m4a"];
      const videoExtensions = ["mp4", "avi", "mov", "wmv"];
      const archiveExtensions = ["zip", "rar", "7z", "tar", "gz"];
      const wordExtensions = ["doc", "docx"];
      const pptExtensions = ["ppt", "pptx"];

      if (imageExtensions.includes(name.substring(name.lastIndexOf('.')+1))) 
        return "mdi-file-jpg-box";
      if (excelExtensions.includes(name.substring(name.lastIndexOf('.')+1)))
        return "mdi-file-excel-box";
      if (name.substring(name.lastIndexOf('.')+1) == "pdf")
        return "mdi-file-pdf-box"
      if (audioExtensions.includes(name.substring(name.lastIndexOf('.')+1)))
        return "mdi-music-box";
      if (videoExtensions.includes(name.substring(name.lastIndexOf('.')+1)))
        return "mdi-video";
      if (archiveExtensions.includes(name.substring(name.lastIndexOf('.')+1)))
        return "mdi-zip-box";
      if (wordExtensions.includes(name.substring(name.lastIndexOf('.')+1)))
        return "mdi-file-word-box";
      if (pptExtensions.includes(name.substring(name.lastIndexOf('.')+1)))
        return "mdi-file-powerpoint-box";
      return "mdi-text-box"
    },
    async getDocumentos() {
      this.loading = true
      const { data } = await axios({
        url: `/documentos_equipo`,
      });
      this.tree = data
      this.tree.empleado.sort((a,b) => {
        let keyA = a.name.toLowerCase();
        let keyB = b.name.toLowerCase();

        if (keyA < keyB) {
            return -1;
        }
        if (keyA > keyB) {
            return 1;
        }
        return 0;
      })
      this.loading = false
    },
    async getObligatorios() {
      this.loading = true
      const { data } = await axios({
        url: `/documentos_equipo/obligatorios`,
      });
      this.uniforme = data.uniforme
      this.prl = data.prl
      this.cc = data.cc
      this.loading = false
    },
    async download(dir, file){
      const {data} = await axios.get(`${process.env.VUE_APP_API_URL}/documentos_equipo/download/${dir}/${file}`,{ responseType: 'blob' })
      
      if (data){
        var url = window.URL.createObjectURL(data);
        var a = document.createElement('a');
        a.href = url;
        a.download = file;
        a.click();
        a.remove();
      }
      else{
        this.$root.$emit("snack", "Error al recuperar el archivo.")
      }
    }
  },
};
</script>

<style scoped>
.sub-item{
  max-height: min-content !important;
}
.v-list-item__icon.v-list-group__header__append-icon{
  margin-left: 0px !important;
}
</style>